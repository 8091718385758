@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  background: #eee;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.react-responsive-modal-modal {
  margin: 0px !important;
}
